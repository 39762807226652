export const AUDIT_STATUS = [
    {
        value: "Pending",
        label: "待审核"
    },
    {
        value: "NotPass",
        label: "未通过"
    },
    {
        value: "Audited",
        label: "已核实"
    }
];

